import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {useAppDispatch, useAppSelector} from 'utils/hooks';

import {showPopup} from 'store/components/PopupSlice';
import {IStore} from 'store/store';
import {clearCart, getCart} from 'store/components/CartSlice';
import Popup from 'components/popup/Popup';
import Timer from 'components/timer/Timer';

import like from './assets/images/like.svg';

import './CompletedFastOrder.scss';

const CompletedFastOrder = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const completedFastOrder = useSelector((state: IStore) => state.popup.popup.completedFastOrder);
  const props = useAppSelector(state => state.popup.props);

  const CompletedOrderListItem = ({item}: { item: any }) => {
    return (
      <>
        <div className={'order-list__item'}>
          <span className={'order-list__name'}>
            <span
              className={'order-list__name_black'}>
              {item.name}
            </span>
          </span>
          <span className={'order-list__validity_period'}>{props.ValidityPeriodNominal}</span>
        </div>
      </>
    );
  };

  const onClose = () => {
    history.push('prizes');
    dispatch(showPopup('testograf'));
    dispatch(clearCart());
    dispatch(getCart());
  };

  return (
    <Popup id={'completedFastOrder'} className={'completed-order'} onClose={onClose}>
      <div className={'completed-order__container'}>
        <img src={like} alt="like" className={'completed-order__icon'} />
        <h2 className={'completed-order__title'}>Заказ оформлен</h2>
        <h4 className={'completed-order__subtitle'}>
          Сертификат{props.nominal?.length > 1 ? 'ы': ''} на сумму <b>{`${props.nominal}`}</b> ₽<br/>
          {props.nominal?.length > 1 ? 'будут доступны:': 'будет доступен:'}
        </h4>
        <div className={'order-list__container'}>
          <CompletedOrderListItem item={props} />
        </div>
        <Timer popupStatus={{completedFastOrder}} onClose={onClose} />
      </div>
    </Popup>
  );
};

export default CompletedFastOrder;
