import React from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {useAppDispatch, useAppSelector} from 'utils/hooks';

import {showPopup} from 'store/components/PopupSlice';
import {setVisibleMenu} from 'store/components/DashboardSlice';
import {logOut} from 'store/components/AuthSlice';
import {GetIcon} from 'store/enums/normalizedIcons';
import {IStore} from 'store/store';

import RulesAndPers from 'components/rulesAndPers/RulesAndPers';
import Link from 'components/link/Link';

import jivochat from './assets/images/jivochat.svg';
import telegram from './assets/images/telegram.svg';
import whatsapp from './assets/images/whatsapp.svg';

import './MenuItem.scss';

const MenuItem = () => {
  const href = window.location.href;
  const domainName = 'mtsfirst';

  const history = useHistory();
  const dispatch = useAppDispatch();

  const user = useAppSelector(state => state.auth.user);
  const purse = useAppSelector((state) => state.dashboard.purse);
  const total = useAppSelector(state => state.cart.total);
  const authorized = useAppSelector(state => state.auth.authorized);
  const visibleMenu = useAppSelector(state => state.dashboard.visibleMenu);
  const cart = useAppSelector(state => state.cart.cart);
  const colors = useSelector((state: IStore) => state.settings.colors);
  const domain = useSelector((state: IStore) => state.settings.currentDomainName);

  const length = cart.reduce((prev, curr) => prev + +curr?.amount, 0);
  const balance = user?.purses?.reduce((prev, curr) => prev + curr.balance, 0);
  const isPlastic = user?.purses?.find(item => item.plastic_included);

  const {menuIconColor, menuIconOpacity, menuIconSecondaryOpacity} = colors[domain];

  const openPage = (path: string) => {
    dispatch(setVisibleMenu(false));
    setTimeout(() => {
      history.push(path);
    }, 300);
  };

  const openActivationCard = () => {
    dispatch(setVisibleMenu(false));
    setTimeout(() => {
      dispatch(showPopup('activationCard'));
    }, 400);
  };

  const openActivationEkoCard = () => {
    dispatch(setVisibleMenu(false));
    setTimeout(() => {
      history.push('/dashboard/activation-eko');
    }, 400);
  };

  const openAuthPopup = () => {
    dispatch(setVisibleMenu(false));
    setTimeout(() => {
      dispatch(showPopup('auth'));
    }, 300);
  };

  const openCheckCard = () => {
    dispatch(setVisibleMenu(false));
    setTimeout(() => {
      dispatch(showPopup('checkCard'));
    }, 300);
  };

  const openJivo = () => {
    // @ts-ignore
    jivo_api.open();
    return false;
  };

  const logOutMenu = () => {
    dispatch(setVisibleMenu(false));
    setTimeout(() => {
      dispatch(logOut());
      history.push('/');
    }, 300);
  };

  return (
    authorized ? (
      <div className={`header__button-container mobile-menu ${visibleMenu ? 'mobile-menu_active' : ''}`}>
        <div className={'menu__nav-menu'}>
          <li className={'menu__item'} onClick={() => openPage('prizes')}>
            <span className={'menu__icon'}>
              <GetIcon
                iconName={'Tile'}
                fill={menuIconColor}
                secondaryFill={menuIconColor}
                secondaryOpacity={menuIconSecondaryOpacity}
                opacity={menuIconOpacity}
                className={'menu__icon-tile'}
              />
            </span>
            <span className={'menu__text'}>Электронные сертификаты</span>
          </li>
          {
            isPlastic ? (
              <li className={'menu__item'} onClick={() => openPage('plastic-prizes')}>
                <span className={'menu__icon'}>
                  <GetIcon
                    iconName={'PlasticCard'}
                    fill={menuIconColor}
                    secondaryFill={menuIconColor}
                    secondaryOpacity={menuIconSecondaryOpacity}
                    opacity={menuIconOpacity}
                    className={'menu__icon-tile'}
                  />
                </span>
                <span className={'menu__text'}>Пластиковые карты</span>
              </li>
            ) : null
          }
          <li className={'menu__item'} onClick={() => openPage('history')}>
            <span className={'menu__icon'}>
              {domain === 'mascoglass' ? (
                <GetIcon
                  iconName={'BurgerMini'}
                  fill={menuIconColor}
                  secondaryFill={menuIconColor}
                  secondaryOpacity={menuIconSecondaryOpacity}
                  opacity={menuIconOpacity}
                  className={'menu__icon-history'}
                />
              ) : (
                <GetIcon
                  iconName={'History'}
                  fill={menuIconColor}
                  secondaryFill={menuIconColor}
                  secondaryOpacity={menuIconSecondaryOpacity}
                  opacity={menuIconOpacity}
                />
              )}
            </span>
            <span className={'menu__text'}>История заказов</span>
          </li>
          <li className={'menu__item menu__text-help'} onClick={() => openPage('help')}>
            <span className={'menu__icon'}>
              <GetIcon
                iconName={'Help'}
                fill={menuIconColor}
                secondaryFill={menuIconColor}
                opacity={menuIconOpacity}
                className={'menu__icon-help'}
              />
            </span>
            <span className={'menu__text'}>Помощь</span>
          </li>
          <li className={'menu__item menu__active-card'} onClick={openActivationCard}>
            <span className={'menu__icon'}>
              <GetIcon
                iconName={'Logo'}
                fill={menuIconColor}
                secondaryFill={menuIconColor}
                opacity={menuIconOpacity}
                className={'menu__icon-logo'}
              />
            </span>
            <span className={'menu__text'}>Активировать сертификат</span>
          </li>
          {
            domain === 'loto-pr'
            || domain === 'rus-exp'
            || domain === 'mts-mission'
            || domain === 'aclub'
            || domain === 'local'
            || domain === 'leader-abc'
            || domain === 'stg'
            || domain === 'rubin'
              ? (<></>)
              : (
                <li className={'menu__item menu__active-card'} onClick={openActivationEkoCard}>
                  <span className={'menu__icon'}>
                    <GetIcon
                      iconName={'Logo'}
                      fill={menuIconColor}
                      secondaryFill={menuIconColor}
                      opacity={menuIconOpacity}
                      className={'menu__icon-logo'}
                    />
                  </span>
                  <span className={'menu__text'}>Активация ЕКО</span>
                </li>
              )
          }
          <li className={'menu__item'} onClick={() => openPage('profile')}>
            <span className={'menu__icon'}>
              <GetIcon
                iconName={'User'}
                fill={menuIconColor}
                secondaryFill={menuIconColor}
                opacity={menuIconOpacity}
                className={'menu__icon-user'}
              />
            </span>
            <span className={'menu__text'}>
              {user?.name?.split(' ')[0]} <span>{balance} ₽</span>
            </span>
          </li>
          <li className={'menu__item'} onClick={() => openPage('cart')}>
            <div className={'authorizes-menu__cart menu-cart'} onClick={() => openPage('cart')}>
              <span className={'menu__icon'}>
                <GetIcon
                  iconName={'Cart'}
                  fill={menuIconColor}
                  secondaryFill={menuIconColor}
                  secondaryOpacity={menuIconSecondaryOpacity}
                  opacity={menuIconOpacity}
                  className={'menu__icon-cart'}
                />
              </span>
              <p className={'menu-cart__faint'}>({length || 0})</p>
              <p className={'menu-cart__sum'}>{total} ₽ </p>
            </div>
          </li>
          <li className={'menu__item'} onClick={logOutMenu}>
            <span className={'menu__icon'}>
              <GetIcon
                iconName={'LogOutIcon'}
                fill={menuIconColor}
                secondaryFill={menuIconColor}
                opacity={menuIconOpacity}
                className={'menu__icon-exit'}
              />
            </span>
            <span className={`menu__text ${href.includes(domainName) ? 'menu__text_black' : 'menu__text_gray'}`}>Выход</span>
          </li>
        </div>
        <div className={'header__help-container'}>
          <div className={'header__help-title'}>
            Получить консультацию:
          </div>
          <div className={'header__jivochat-container'} onClick={openJivo}>
            <img src={jivochat} alt="jivochat" className={'header__jivochat-icon'}/>
            <Link>Jivo-чат</Link>
          </div>
          <a
            href={'https://t.me/presentcard_bot'}
            target={'_blank'}
            rel="noreferrer"
            className={'header__telegram-container'}>
            <img src={telegram} alt="telegram" className={'header__telegram-icon'}/>
            <Link>presentcard_bot</Link>
          </a>
          <a
            href={'https://wa.me/79016345854'}
            target={'_blank'}
            rel="noreferrer"
            className={'header__telegram-container'}>
            <img src={whatsapp} alt="whatsapp" className={'header__whatsapp-icon'}/>
            <Link>WhatsApp</Link>
          </a>
          <RulesAndPers />
        </div>
      </div>
    ) : (
      <div className={`header__button-container mobile-menu ${visibleMenu ? 'mobile-menu_active' : ''}`}>
        <div className={'header__auth-container'}>
          <div className={'header__link-container'}>
            <Link
              img={<GetIcon
                iconName={'User'}
                fill={menuIconColor}
                secondaryFill={menuIconColor}
                opacity={menuIconOpacity}
              />}
              classname={'header__link-item'}
              onClick={openAuthPopup}>
              Войти
            </Link>
            <Link
              img={<GetIcon
                iconName={'Card'}
                fill={menuIconColor}
                secondaryFill={menuIconColor}
                opacity={menuIconOpacity}
              />}
              classname={'header__link-item'}
              onClick={openCheckCard}>
              Проверить баланс
            </Link>
          </div>
        </div>
        <div className={'header__help-container'}>
          <div className={'header__help-title'}>
            Получить консультацию:
          </div>
          <div className={'header__jivochat-container'} onClick={openJivo}>
            <img src={jivochat} alt="jivochat" className={'header__jivochat-icon'}/>
            <Link>Jivo-чат</Link>
          </div>
          <a
            href={'https://t.me/presentcard_bot'}
            target={'_blank'}
            rel="noreferrer"
            className={'header__telegram-container'}>
            <img src={telegram} alt="telegram" className={'header__telegram-icon'}/>
            <Link>presentcard_bot</Link>
          </a>
          <a
            href={'https://wa.me/79016345854'}
            target={'_blank'}
            rel="noreferrer"
            className={'header__telegram-container'}>
            <img src={whatsapp} alt="whatsapp" className={'header__whatsapp-icon'}/>
            <Link>WhatsApp</Link>
          </a>
        </div>
      </div>
    )
  );
};

export default MenuItem;
