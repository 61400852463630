import React from 'react';

import {IHistoryOrders} from 'store/components/DashboardSlice';

import StatusHistory from '../../../status/StatusHistory';

const Table = ({positions}: {positions: any}) => {
  if (!positions) {
    return;
  }

  return (
    positions.map((position: any, index: number) => {
      const pos = {
        ...position
      };

      return (
        <tbody key={position.id}>
          <tr className={'pull-basket__card'}>
            <td
              className={`
                pull-basket__card-id
                ${position.status_title === 'Отказ' ? 'pull-basket__card-id_red' : ''}
              `}>
              <span className={'pull-basket__card-id_black'}>
                №{index + 1}
              </span>
              {position.is_changed ? <span className={'pull-basket__position-is-changed'}>Новая позиция</span> : null}
              <div
                className={`
                  pull-basket__card-title
                  pull-basket__card-title-container
                  pull-basket__card-title-container_mobile
                `}>
                <span
                  className={`
                  pull-basket__card-title_black
                  ${position.status_title === 'Отказ' ? 'pull-basket__card-title_red' : ''}
                  `}>
                  {position.name}
                </span>
              </div>
            </td>
            <td className={'pull-basket__card-title'}>
              <div
                className={`
                  pull-basket__card-title-container_desktop
                  pull-basket__card-title-container
                `}>
                <span
                  className={`
                    pull-basket__card-title_black
                    ${position.status_title === 'Отказ' ? 'pull-basket__card-title_red' : ''}
                  `}>
                  {position.name}
                </span>
              </div>
            </td>
            <td className={'pull-basket__card-number'}/>
            <td
              className={`
                pull-basket__card-sum
                pull-basket__card-sum_desktop
                ${position.status === 4 ? 'pull-basket__card-sum_grey' : ''}
                ${position.status_title === 'Отказ' ? 'pull-basket__card-sum_red' : ''}
              `}>
              {position.nominal} ₽
            </td>
            <td
              className={`
                pull-basket__card-sum
                pull-basket__card-sum_desktop
                ${position.status === 4 ? 'pull-basket__card-sum_grey' : ''}
                ${position.status_title === 'Отказ' ? 'pull-basket__card-sum_red' : ''}
              `}>
              {
                position.markup_nominal
                  ? (
                    <span className={'pull-basket__card-markup'}>
                      {position.markup_nominal} ₽
                    </span>
                  )
                  : null
              }
            </td>
            <td className={'pull-basket_mobile'}/>
            <td
              className={`
                pull-basket__card-sum
                pull-basket__card-sum_desktop
                ${position.status === 4 ? 'pull-basket__card-sum_grey' : ''}
                ${position.status_title === 'Отказ' ? 'pull-basket__card-sum_red' : ''}
              `}>
              <StatusHistory
                type={'orders'}
                status={pos.status}
                is_changed={position.is_changed}
                link={position.link}
                id={position.id}
                status_label={position.status_title}
              />
            </td>
          </tr>
          <tr className={'pull-basket_mobile-row'}>
            <td>
              <span className={'pull-basket__card-sum-title'}>
                Номинал
              </span>
              <span
                className={`
                  pull-basket__card-sum
                  pull-basket__card-sum_mobile
                  ${position.status_title === 'Отказ' ? 'pull-basket__card-sum_red' : ''}
                  ${position.status === 4 ? 'pull-basket__card-sum_grey' : ''}
                `}>
                {position.sum} ₽
              </span>
            </td>
            <td>
              {position.markup_nominal ? (
                <>
                  <span className={'pull-basket__card-sum-title'}>
                    Комиссия
                  </span>
                  <span
                    className={`
                      pull-basket__card-sum
                      pull-basket__card-sum_mobile
                      ${position.status_title === 'Отказ' ? 'pull-basket__card-sum_red' : ''}
                      ${position.status === 4 ? 'pull-basket__card-sum_grey' : ''}
                    `}>
                    {position.markup_nominal} ₽
                  </span>
                </>
              ) : null}
            </td>
            <td className={'pull-basket__status'}>
              <span className={'pull-basket__status-title_mobile'}>
                  Статус заказа
              </span>

              <StatusHistory
                type={'orders'}
                status={pos.status}
                is_changed={position.is_changed}
                id={position.id}
                link={position.link}
                status_label={position.status_title}
              />
            </td>
          </tr>
        </tbody>
      );
    })
  );
};

export default Table;