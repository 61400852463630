import React, {Dispatch, SetStateAction} from 'react';

import './PrivateDilveryRadioButton.scss';

interface IRadioButtons {
  list: any;
  value: {
    value: string,
  };
  setValue: Dispatch<SetStateAction<string>>;
  setVisibleForm: Dispatch<SetStateAction<boolean>>;
}

const PrivateDeliveryRadioButton: React.FC<IRadioButtons> = ({list, value, setValue, setVisibleForm}) => {
  if (!list) {
    return null;
  }

  return (
    <div className={'private-delivery-radio-buttons'}>
      {
        list.map((item: any) => (
          <button
            key={item.id}
            className={`
              private-delivery-radio-buttons__button
              ${item.value === value.value ? 'private-delivery-radio-buttons__button--selected' : ''}
            `}
            onClick={() => {
              setValue(item);
              setVisibleForm(true);
            }}
            type="button">
            {item.value}
          </button>
        ))
      }
    </div>
  );
};

export default PrivateDeliveryRadioButton;