export const suffix = ({value, qty}: any) => {
  const suf: any = {
    certificate: {
      first: 'ов',
      second: 'ов',
      third: '',
      fourth: 'a',
    },
    certificateStepNominal: {
      first: '',
      second: 'ы',
      third: '',
      fourth: 'ы',
    },
    plastic: {
      first: 'ых',
      second: 'ых',
      third: 'ая',
      fourth: 'ые',
    },
    card: {
      first: '',
      second: '',
      third: 'а',
      fourth: 'ы',
    }
  };

  if (!qty) {
    return suf[value].first;
  }

  const count = qty >= 20 ? Number(`${qty}`[`${qty}`.length - 1]) : qty;

  if (count >= 5 || count === 0 || count > 10 && count <= 20 && count) {
    return suf[value].second;
  }
  if (count === 1) {
    return suf[value].third;
  }
  return suf[value].fourth;
};