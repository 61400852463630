import React from 'react';
import {useHistory} from 'react-router';
import {useAppDispatch} from 'utils/hooks';
import Popup from 'components/popup/Popup';

import {hidePopup} from 'store/components/PopupSlice';

import './ErrorPlaceOrderPopup.scss';

const ErrorPlaceOrderPopup = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const route = () => {
    dispatch(hidePopup('errorPlaceOrder'));
    history.push('help');
  };

  return (
    <Popup id={'errorPlaceOrder'} className={'error-place-order'}>
      <div className={'error-place-order__container'}>
        <h2 className={'error-place-order__title'}>
          Произошла непредвиденная ошибка. Пожалуйста, попробуйте еще раз позже.
        </h2>
        <p className={'error-place-order__description'}>
          Если проблема не устраняется, вы можете обратиться за помощью в наш раздел{' '}
          <a onClick={route} className={'error-place-order__link'}>Помощь</a>.
        </p>
      </div>
    </Popup>
  );
};

export default ErrorPlaceOrderPopup;
