import React from 'react';

import {useAppSelector} from 'utils/hooks';
import {GetIcon} from 'store/enums/normalizedIcons';

import RadioButtons from './components/radioButtons/RadioButtons';
import Form from '../form/Form';
import DropDown from '../dropDown/DropDown';

import './DefaultDelivery.scss';

const DefaultDelivery = ({...props}: any) => {
  const projectSettings = useAppSelector((state) => state.auth.projectSettings);

  return (
    <div className={'default-delivery'}>
      <div className={'default-delivery__form-container'}>
        <h3 className={'default-delivery__title'}>
          {
            props.selectedAddress ? (
              props.selectedAddress.price > 0
                ? `Стоимость доставки по ${props.selectedAddress.value} - ${props.selectedAddress.price} рублей`
                : 'Доставка осуществляется бесплатно.'
            ) : 'Выберите адрес для расчета стоимости.'
          }
        </h3>
        {
          projectSettings!.delivery_addresses && projectSettings!.delivery_addresses.length > 3  ? (
            <DropDown
              list={projectSettings!.delivery_addresses}
              value={props.selectedAddress}
              setValue={props.setSelectedAddress}
            />
          ) : (
            <RadioButtons
              list={projectSettings!.delivery_addresses}
              value={props.selectedAddress}
              setValue={props.setSelectedAddress}
            />
          )
        }
        <Form {...props} delivery_type={'default_delivery'} />
      </div>
      <div className={'default-delivery__info-container'}>
        <GetIcon iconName={'PlasticInfo'} />
        <p className={'default-delivery__text'}>
          Приём заявок на обмен и доставку заказанных пластиковых карт и
          электронных сертификатов осуществляется следующим образом
        </p>
        <h3 className={'default-delivery__item'}>1. Доставка пластиковых карт:</h3>
        <ul className={'default-delivery__list'}>
          {
            projectSettings!.delivery_addresses && projectSettings!.delivery_addresses.slice(0, 3).map((item: any, index: any) => (
              <li key={index} className={'default-delivery__text'}>
                По адресу {item.value}.{' '}
                {
                  item.price > 0
                    ? `Стоимость доставки на данный адрес ${item.price} рублей`
                    : 'Доставка осуществляется бесплатно.'
                }
              </li>
            ))
          }
          <li className={'default-delivery__text'}>
            Доставка пластиковых карт — в течение 5 рабочих дней с момента оформления заказа.
          </li>
        </ul>
        <h3 className={'default-delivery__item'}>
          2. Доставка электронных сертификатов:
        </h3>
        <ul className={'default-delivery__list'}>
          <li className={'default-delivery__text'}>
            Электронные сертификаты отправляются на email, указанный клиентом в Личном кабинете
            или доступны для скачивания в разделе История заказов
          </li>
          <li className={'default-delivery__text'}>
            Сроки доставки соответствуют установленным при оформлении заказа
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DefaultDelivery;