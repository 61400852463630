import React, {useState} from 'react';
import {CSSTransition, SwitchTransition} from 'react-transition-group';
import ReactLoading from 'react-loading';

import './Button.scss';

interface IButton {
  children: any;
  onClick?: any;
  color?: string;
  loading?: boolean;
  icon?: any;
  width?: number | string;
  backgroundColor?: string;
  disabled?: boolean;
  marginBottom?: number;
  marginRight?: number;
  borderRadius?: number;
  uppercase?: boolean;
  className?: any;
  iconFloat?: string;
  fontSize?: string;
  height?: string;
  loaderColor?: string;
}

const Button: React.FC<IButton> = ({uppercase = true, iconFloat = 'left', loaderColor = '#fff', ...props}) => {
  const buttonContainerInlineStyle = {
    width: props.width,
    height: props.height,
    background: props.backgroundColor,
    opacity: props.disabled ? 0.6 : 1,
    marginBottom: props.marginBottom,
    marginRight: props.marginRight,
    borderRadius: props.borderRadius,
  };
  const buttonInlineStyle = {
    color: props.color,
    fontSize: props.fontSize,
  };

  const [animationClick, setAnimationClick] = useState<boolean>(false);

  const handleMouseDown = () => {
    setAnimationClick(true);
    setTimeout(() => setAnimationClick(false), 300);
  };

  const onClick = () => {
    if (!props.disabled) {
      props.onClick && props.onClick();
    }
  };

  return (
    <div
      {...props}
      onMouseDown={handleMouseDown}
      className={[animationClick ? 'button button_click' : 'button', props.className].join(' ')}
      style={buttonContainerInlineStyle}
      onClick={onClick}>
      <SwitchTransition mode={'out-in'}>
        <CSSTransition key={`${props.loading}`} timeout={500} classNames={'button__animation'}>
          {
            props.loading ? (
              <div className={'button__container'}>
                <ReactLoading type={'spin'} color={loaderColor} width={24} height={24} />
              </div>
            ) : (
              <div className={'button__container'}>
                {
                  props.icon && iconFloat === 'left'
                    ? <span className={'button__icon'}>{props.icon}</span>
                    : null
                }
                <span
                  className={
                    uppercase
                      ? 'button__text button__text_uppercase'
                      : 'button__text'
                  }
                  style={buttonInlineStyle}>
                  {props.children}
                </span>
                {
                  props.icon && iconFloat === 'right'
                    ? <span className={'button__icon button__icon_margin-left'}>{props.icon}</span>
                    : null
                }
              </div>
            )
          }
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default Button;
