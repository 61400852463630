import React, {useEffect, useState} from 'react';

import {useAppDispatch, useAppSelector} from 'utils/hooks';
import {
  addMarkupsToCards,
  getCards,
  getMarkups,
  setActiveAvailability,
  setActiveCategory
} from 'store/components/PrizesSlice';
import {setPurse} from 'store/components/DashboardSlice';

import Cards from '../prizes/assets/components/cards/Cards';
import HeaderPrizes from '../prizes/assets/components/headerPrizes/HeaderPrizes';

import './PlasticPrizes.scss';

const PlasticPrizes = () => {
  const user = useAppSelector(state => state.auth.user);

  const dispatch = useAppDispatch();
  const category = useAppSelector((state) => state.prizes.categories);
  const {prizes: cardsLoading} = useAppSelector((state) => state.prizes.loading);
  const searchQuery = useAppSelector((state) => state.prizes.searchQuery);
  const purse = useAppSelector(state => state.dashboard.purse);
  const cards = useAppSelector(state => state.prizes.cards);
  const total = useAppSelector(state => state.prizes.cardsTotal);
  const changeSearchData = useAppSelector(state => state.prizes.changeSearchData);
  const [step, setStep] = useState(0);

  const stepLen = 24;

  if (!user) {
    return null;
  }

  useEffect(() => {
    dispatch(setPurse(user?.purses
      .filter(item => item.plastic_included)[0]));
  }, []);

  useEffect(() => {
    const scrollTarget = document.getElementById('body');
    const topOffset = 0;

    const elementPosition = scrollTarget && scrollTarget.getBoundingClientRect().top || 0;
    const offsetPosition = elementPosition - topOffset;

    window.scrollBy({
      top: offsetPosition || 0,
      behavior: 'smooth'
    });

  }, [user]);

  useEffect(() => {
    if (Object.keys(cards).length < total && (step * stepLen <= total)) {
      dispatch(getCards({from: step * stepLen, to: (step + 1) * stepLen, modeEps: 'plastic'})).then(() => {
        dispatch(addMarkupsToCards());
      });
    }
  }, [step]);

  useEffect(() => {
    if (changeSearchData) {
      setStep(0);
      dispatch(getCards({from: 0, to: stepLen, modeEps: 'plastic'}));
    }
  }, [changeSearchData]);

  useEffect(() => {
    setStep(0);
    if (category.length) {
      dispatch(setActiveCategory(0));
    }
    dispatch(setActiveAvailability(0));
    dispatch(getCards({from: 0, to: stepLen, mode: true, modeEps: 'plastic'})).then(() => {
      dispatch(getMarkups());
    });
  }, [purse?.id]);

  return (
    <div className={'prizes'}>
      <HeaderPrizes mode={'plastic'} />
      <Cards step={step} setStep={setStep} mode={'plastic'} />
    </div>
  );
};

export default PlasticPrizes;
