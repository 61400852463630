import React from 'react';

import './TabMenuItem.scss';

interface ITabMenuItem {
  title: any;
  Icon: any;
  active: boolean;
  setActive: any;
  index: number;
  callback: () => void;
}

const TabMenuItem = ({title, Icon, setActive, index, active, callback}: ITabMenuItem) => {
  const clickMenuItem = () => {
    setActive(index);
    callback();
  };

  return (
    <div
      className={
        active
          ? 'tab-item__button-container tab-item__button-container_active'
          : 'tab-item__button-container'
      }>
      <div
        className={
          active
            ? 'tab-item__button tab-item__button_active'
            : 'tab-item__button'
        }
        onClick={clickMenuItem}>
        <Icon />
      </div>
    </div>
  );
};

export default TabMenuItem;
