import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {useAppDispatch} from 'utils/hooks';
import {rusLettersReg} from 'utils/stringFormatter';
import {errorTimer} from 'utils/errorTimer';
import {IStore} from 'store/store';
import {logOut} from 'store/components/AuthSlice';
import {updateProfile} from 'store/components/ProfileSlice';
import {showPopup} from 'store/components/PopupSlice';
import {limiter} from 'utils/limiter';

import SecondInput from 'components/secondInput/SecondInput';
import VerifiedEmail from 'components/verifiedEmail/VerifiedEmail';
import UserField from '../userField/UserField';
import Button from 'components/button/Button';

import './ProfileInfo.scss';

const ProfileInfo = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const user = useSelector((state: IStore) => state.auth.user);
  const is_required_email = useSelector((state: IStore) => state.auth.is_required_email);

  if (!user) {
    return null;
  }

  const userName = user.name.slice(0, user.name.indexOf(' '));
  const userSecondName = user.name.slice(user.name.indexOf(' ') + 1);

  const [name, setName] = useState<string>(userName || '');
  const [secondName, setSecondName] = useState<string>(userSecondName || '');

  const [nameError, setNameError] = useState<string>('');
  const [secondNameError, setSecondNameError] = useState<string>('');

  const [email, setEmail] = useState<string>(user.email || '');
  const [phone, setPhone] = useState<string>(user.phone || '');
  const [code, setCode] = useState<string>( '');
  const [codeWithApi, setCodeWithApi] = useState<string>( '');

  const [errorEmail, setErrorEmail] = useState<string>('');
  const [errorPhone, setErrorPhone] = useState<string>('');
  const [visibleInputCode, setVisibleInputCode] = useState<boolean>(false);

  const fullName = `${name} ${secondName}`;

  const changeProfile =
        userName !== name
        || secondName !== userSecondName
        || email !== user.email
        || phone.replace(/[\s+]/g, '') !== user.phone;

  const disabledButton =
        (name && secondName && phone) === ''
        || (is_required_email && email === '')
        || phone.includes('_')
        || code !== codeWithApi
        || (!email.includes('@') && is_required_email);

  const logOutButton = () => {
    dispatch(logOut());
    history.push('/');
  };

  useEffect(() => {
    setEmail(user.email);
    setPhone(user.phone);
  }, [user.email, user.phone]);

  const updateProfileForm = () => {
    const payloadSetState = {
      setErrorEmail,
      setErrorPhone,
      setCodeWithApi,
      setVisibleInputCode,
      setNameError,
      setSecondNameError,
    };

    dispatch(updateProfile({
      name: name,
      surname: secondName,
      code,
      codeWithApi,
      phone: `+${phone.replace(/[^\d+]/g, '')}`,
      email,
      payloadSetState,
      is_required_email,
    })).then((res: any) => {
      if (res.code === code) {
        setVisibleInputCode(false);
      }
    });
  };

  useEffect(() => {
    if (!user.status && is_required_email) {
      setErrorEmail('Обязательное поле для оформления заказа');
    }
  }, []);

  const openPopup = (type: string) => {
    dispatch(showPopup({popup: 'changeUserData', props: type}));
  };

  const handleChange = (
    value: string,
    setValue: (value: string) => void,
    setError: (data: string) => void,
  ) => {
    if ((rusLettersReg.test(value) || value.length === 0) && value.length <= 50) {
      limiter(value, setValue, 50);
      setError('');
    } else {
      errorTimer(setError, 'Ввод недопустимых символов');
    }
  };

  return (
    <div className={'profile-info'}>
      <p className={'profile-info__description'}>Вы можете изменить свои личные данные, подтвердить почту</p>
      <div className={'profile-info__info-container'}>
        <div>
          <SecondInput
            value={name}
            setValue={(value: string) => handleChange(value, setName, setNameError)}
            marginBottom={16}
            error={nameError}
            title={'Имя'}
          />
          <SecondInput
            value={secondName}
            setValue={(value: string) => handleChange(value, setSecondName, setSecondNameError)}
            marginBottom={16}
            error={secondNameError}
            title={'Фамилия'}
          />
        </div>
        <div>
          <div className={'profile-info__email'}>
            <UserField title={'Почта'} isMask={false} data={user.email} onClick={() => openPopup('email')} />
            {user.email
              ? (<VerifiedEmail />)
              : null
            }
          </div>
          <UserField title={'Телефон'} isMask={true} data={user.phone} onClick={() => openPopup('phone')} />
        </div>
      </div>
      {
        visibleInputCode ? (
          <SecondInput
            value={code}
            setValue={setCode}
            marginBottom={24}
            isMask
            title={'Введите последние 4 цифры из входящего звонка'}
            mask={[/\d/, /\d/, /\d/, /\d/]}
            type={'text'}
          />
        ) : null
      }
      {changeProfile ? (
        <div
          className={
            changeProfile
              ? 'profile-info__button-container profile-info__button-container_active'
              : 'profile-info__button-container'
          }>
          <Button
            disabled={!changeProfile || disabledButton}
            onClick={updateProfileForm}
            width={166}>
            Сохранить
          </Button>
        </div>)
        : null}
      <span onClick={logOutButton} className={'profile-info__log-out'}>Выход</span>
    </div>
  );
};

export default ProfileInfo;
