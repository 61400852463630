import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from 'utils/hooks';

import {IStore} from 'store/store';
import {showPopup} from 'store/components/PopupSlice';
import {getUser} from 'store/components/ProfileSlice';
import {clearError} from 'store/components/DashboardSlice';

import Title from 'components/title/Title';
import InnerTabs from 'components/innerTabs/InnerTabs';
import DefaultDelivery from './components/defaultDelivery/DefaultDelivery';
import PrivateDelivery from './components/privateDelivery/PrivateDelivery';
import Form from './components/form/Form';

import arrow from './assets/images/arrow.svg';

import './PlaceOrder.scss';

const PlaceOrder = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const user = useSelector((state: IStore) => state.auth.user);
  const error = useSelector((state: IStore) => state.dashboard.error);
  const cart = useAppSelector(state => state.cart.cart);
  const projectSettings = useAppSelector((state) => state.auth.projectSettings);

  if (!user) {
    return null;
  }

  const userName = user.name.slice(0, user.name.indexOf(' '));
  const userSecondName = user.name.slice(user.name.indexOf(' ') + 1);

  const [name, setName] = useState<string>(userName || '');
  const [secondName, setSecondName] = useState<string>(userSecondName || '');
  const [email, setEmail] = useState<string>(user.email || '');
  const [phone, setPhone] = useState<string>(user.phone || '');
  const [code, setCode] = useState<string>( '');
  const [selectedAddress, setSelectedAddress] = useState('');
  const [selectedCity, setSelectedCity] = useState({});
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState<boolean>( false);
  const [loadingUser, setLoadingUser] = useState(true);

  const checkPlastic = cart.find((item: any) => !item.is_eps);
  const checkEps = cart.find((item: any) => item.is_eps);

  const props = {
    selectedAddress,
    setSelectedAddress,
    name,
    setName,
    secondName,
    setSecondName,
    email,
    setEmail,
    phone,
    setPhone,
    loading,
    setLoading,
    loadingUser
  };

  const tabBar = [
    {
      name: 'Корпоративная доставка',
      child: <DefaultDelivery {...props} />,
      hidden: projectSettings.delivery_addresses && projectSettings.delivery_addresses.length <= 0,
    },
    {
      name: 'Доставка на личный адрес ',
      child: (
        <PrivateDelivery
          {...props}
          comment={comment}
          setComment={setComment}
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
        />
      ),
      hidden:
        !projectSettings.private_delivery ||
        (projectSettings.delivery_cities && projectSettings.delivery_cities.length <= 0),
    }
  ];

  const [active, setActive] = useState(tabBar.findIndex((item) => !item.hidden) || 0);

  const goBack = () => {
    history.push('cart');
  };

  const changeActiveTab = (index: number) => {
    setActive(index);
    setSelectedAddress('');
    setSelectedCity({});
    setComment('');
  };

  const checkDescription = () => {
    if (checkEps && checkPlastic) {
      return 'Данные для отправки заказа';
    }

    if (checkEps) {
      return 'Данные для отправки электронного подарочного сертификата';
    }

    if (checkPlastic) {
      return 'Данные для отправки пластиковой подарочной карты';
    }

    return 'Произошла непредвиденная ошибка. Пожалуйста, попробуйте еще раз позже.';
  };

  useEffect(() => {
    dispatch(getUser()).then(() => {
      setLoadingUser(false);
    });
  }, []);

  useEffect(() => {
    if (error) {
      dispatch(showPopup({
        props: {
          error: `${error}`,
          onClose: () => {dispatch(clearError());}
        },
        popup: 'transferOrderReject'
      }));
    }
  }, [error]);


  useEffect(() => {
    if (
      checkPlastic && (
        projectSettings.delivery_cities?.length === 0 &&
        projectSettings.delivery_addresses?.length === 0
      )
    ) {
      dispatch(showPopup('errorPlaceOrder'));
    }
  }, [projectSettings]);

  return (
    <div className={'place-order'}>
      <div className={'place-order__container'}>
        <div className={'place-order__step-back-container'} onClick={goBack}>
          <img src={arrow} alt="arrow" className={'place-order__arrow'}/>
          <span className={'place-order__step-back'}>Шаг назад</span>
        </div>
        <Title>Оформление заказа</Title>
        <h3 className={'place-order__subtitle'}>
          {checkDescription()}
        </h3>
        {
          !checkPlastic
            ? <Form {...props} />
            : (
              projectSettings.delivery_cities && projectSettings.delivery_cities.length > 0 ||
              projectSettings.delivery_addresses && projectSettings.delivery_addresses.length > 0
            ) ? <InnerTabs changeActiveTab={changeActiveTab} tabs={tabBar} activeTab={active} />
              : (
                <p>
                  Если проблема не устраняется, вы можете обратиться за
                  помощью в наш раздел <a onClick={() => history.push('help')} className={'place-order__link'}>Помощь</a>.
                </p>
              )
        }
      </div>
    </div>
  );
};

export default PlaceOrder;
