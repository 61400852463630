import React from 'react';
import {useHistory} from 'react-router-dom';

import {useAppDispatch, useAppSelector} from 'utils/hooks';

import {GetIcon} from 'store/enums/normalizedIcons';
import {showPopup} from 'store/components/PopupSlice';
import {setVisibleMenu} from 'store/components/DashboardSlice';

import Link from 'components/link/Link';
import Button from 'components/button/Button';

import './AuthorizedMenu.scss';

const AuthorizedMenu = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const user = useAppSelector(state => state.auth.user);
  const total = useAppSelector(state => state.cart.total);
  const cart = useAppSelector(state => state.cart.cart);
  const ndfl = useAppSelector(state => state.cart.total_tax);
  const colors = useAppSelector(state => state.settings.colors);
  const domain = useAppSelector(state => state.settings.currentDomainName);

  const balance = user?.purses?.reduce((prev, curr) => prev + curr.balance, 0);

  const href = window.location.href;
  const isRaiffeisen = href.includes('raiffeisen');

  const statuses = [
    'empty',
    'moderate',
    'approve',
    'reject',
  ];

  const length = cart.reduce((prev, curr) => prev + +curr?.amount, 0);

  const {firstColorIconIdle, firstColorIconActive, secondColorIconActive, secondColorIconIdle, cartIconOpacity} = colors[domain];

  if (!user) {
    return null;
  }

  const openPage = (path: string) => {
    history.push(path);
  };

  const openActivationCard = () => {
    dispatch(showPopup('activationCard'));
  };

  const openMenu = () => {
    dispatch(setVisibleMenu(true));
  };

  return (
    <div className={`authorizes-menu ${isRaiffeisen ? 'authorizes-menu_justify-between' : ''}`}>
      <Button
        icon={<GetIcon iconName={'MiniLogoWhite'} fill={colors[domain].logoIconColor} />}
        width={290}
        className={'authorizes-menu__button'}
        marginRight={30}
        onClick={openActivationCard}>
        Активировать сертификат
      </Button>
      <div className={'authorizes-menu__info-container'}>
        {user.tax ? (
          <div
            className={`
              authorizes-menu__ndfl
              authorizes-menu__ndfl-animation_${user.tax ? statuses[user.tax.status] : ''} 
              menu-cart
            `}
            onClick={() => history.push('profile#ndfl')}>
            <span className={'menu-cart__image'}>
              <GetIcon
                iconName={'ClipBoard'}
                opacity={cartIconOpacity}
                fill={firstColorIconIdle}
                secondaryFill={secondColorIconIdle}
              />
            </span>
            <p className={'menu-cart__faint'}>НДФЛ</p>
            <div
              className={`
              authorizes-menu__ndfl-status 
              authorizes-menu__ndfl-status_${user.tax ? statuses[user.tax?.status] : 'empty'}
                `} />
          </div>
        ) : null}
        <div className={'authorizes-menu__cart menu-cart'} onClick={() => openPage('cart')}>
          <span className={'menu-cart__image'}>
            {domain === 'leader-abc' ? (
              <GetIcon
                iconName={'CartAuthMenu'}
                opacity={'1'}
                fill={firstColorIconActive}
                secondaryFill={secondColorIconActive}
              />
            ) : (
              <GetIcon
                iconName={'CartAuthMenu'}
                opacity={cartIconOpacity}
                fill={firstColorIconIdle}
                secondaryFill={secondColorIconIdle}
              />
            )}
          </span>
          <p className={'menu-cart__faint'}>({length || 0})</p>
          <p className={'menu-cart__sum'}>{total} ₽ </p>
        </div>
        <Link
          img={domain === 'leader-abc'
            ? (
              <GetIcon
                iconName={'User'}
                fill={firstColorIconActive}
                secondaryFill={secondColorIconActive}
              />
            ) : (
              <GetIcon
                iconName={'User'}
                fill={firstColorIconIdle}
                secondaryFill={secondColorIconIdle}
                className={'link__icon'}
              />
            )}
          marginRight={8}
          onClick={() => openPage('profile')}>
          {user.name}
        </Link>
        <p className={'authorizes-menu__balance'}>{balance} ₽</p>
      </div>
      <span onClick={openMenu} className={'menu__burger'}>
        <GetIcon
          iconName={'BurgerMainMenu'}
          fill={colors[domain].firstColorIconIdle}
          secondaryFill={colors[domain].secondColorIconIdle}
        />
      </span>
    </div>
  );
};

export default AuthorizedMenu;
