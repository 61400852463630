import React, {useState} from 'react';

import {useAppSelector} from 'utils/hooks';
import WindowLoading from 'components/windowLoading/WindowLoading';
import List from './components/list/List';

import './ListCarts.scss';

const ListCarts= () => {
  const cart = useAppSelector(state => state.cart.cart);
  const markup = useAppSelector(state => state.cart.total_markup);
  const [error, setError] = useState(false);

  const checkPlastic = cart?.find(item => !item.is_eps);
  const checkVirtual = cart?.find(item => item.is_eps);

  return (
    <div className={'list-cart'}>
      <WindowLoading id={'cartLoading'} className={'list-cart__loading'} />
      <div className={'list-cart__container'}>
        <div className={'list-cart__table-head cart-head'}>
          <div className={'cart-head__label list-cart__label_name'}>Наименование</div>
          <div className={'cart-head__container'}>
            <div className={'cart-head cart-head__nominal'}>Номинал</div>
            {
              markup
                ? (
                  <div className={'cart-head cart-head__commission'}>Комиссия</div>
                )
                : null
            }
            <div className={'cart-head cart-head__count'}>Кол-во</div>
            <div className={'cart-head cart-head__sum'}>Итого</div>
            <div className={'cart-head cart-head__cross'}/>
          </div>
        </div>
        <div className={'cart-head__table-body'}>
          {checkVirtual && checkPlastic ? (<div className={'cart-head__table-head'}>Электронные сертификаты</div>) : null}
          <List eps={true} setError={setError} />
          {
            checkPlastic ? (
              <>
                <div className={'cart-head__table-head'}>Пластиковые карты</div>
                <List eps={false} setError={setError} />
              </>
            ) : null
          }
        </div>
      </div>
      <div className={'list-cart__bottom-holder'} />
      <div className={'list-cart__error'}>{error ? 'Недостаточно средств в кошельке' : ' '}</div>
    </div>
  );
};

export default ListCarts;
