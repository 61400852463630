import React, {useEffect, useState} from 'react';

import {useAppDispatch, useAppSelector} from 'utils/hooks';

import {getCode} from 'store/components/AuthSlice';

import Button from 'components/button/Button';

import './Timer.scss';

interface ITimer {
  phone: string;
  onGetRepeatCode: () => any;
  setError?: any
}

const Timer: React.FC<ITimer> = ({phone, onGetRepeatCode, setError}) => {
  const dispatch = useAppDispatch();

  const codeData = useAppSelector(state => state.auth.user?.code_data);
  const variantSMS = 'в сообщении';
  const variantCall = 'по звонку';

  const [sendCodeType, setSendCodeType] = useState(true);
  const [timer, setTimer] = useState<number>(60);
  const [loading, setLoading] = useState<boolean>(false);

  const getCodeRepeat = async (sendType: boolean) => {
    if (!loading) {
      setLoading(true);

      await onGetRepeatCode().then((captcha: string) => {
        const data = {
          phone,
          captcha,
          captcha_version: 'v3',
          send_code: !sendType ? 'sms' : undefined
        };
        dispatch(getCode(data)).then(({payload}: any) => {
          if (payload) {
            setLoading(false);
          }
        });
      });
    }
  };

  useEffect(() => {
    setTimer(59);

    if (codeData?.status === 'error') {
      setError(codeData.message);
      setTimeout(() => setError(''), 3000);
    }
  }, [codeData]);

  const changeVariantSendCode = () => {
    setSendCodeType(!sendCodeType);
    getCodeRepeat(!sendCodeType);
  };

  useEffect(() => {
    const counter =
      setInterval(() => setTimer(timerInner => timerInner - 1), 1000);

    if (timer < 0) {
      clearInterval(counter);
    }

    return () => clearInterval(counter);
  }, [timer]);

  return (
    <div className={'timer'}>
      <p className={'timer__title'}>Не получили код?</p>
      {
        timer > 0 ? (
          <p className={'timer__counter'}>
             Отправить код повторно можно будет через 0:{timer < 10 ? `0${timer}` : timer}
          </p>
        ) : (
          <>
            <a
              href="#"
              className={
                timer < 0
                  ? 'timer__link timer__link_active'
                  : 'timer__link'
              }
              onClick={() => getCodeRepeat(sendCodeType)}>
              Отправить еще раз
            </a>
            <Button
              width={'100%'}
              className={'timer__button'}
              loading={loading}
              loaderColor={'#4661c4'}
              backgroundColor={'#fff'}
              color={'#4661c4'}
              onClick={changeVariantSendCode}>
              Получить код {sendCodeType ? variantSMS : variantCall}
            </Button>
            <div></div>
          </>
        )
      }
    </div>
  );
};

export default Timer;
