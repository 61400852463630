import React, {Dispatch, SetStateAction} from 'react';

import './RadioButtons.scss';

interface IRadioButtons {
  list: any;
  value: {value : string};
  setValue: Dispatch<SetStateAction<string>>;
}

const RadioButtons: React.FC<IRadioButtons> = ({list, value, setValue}) => {
  const handleRadioChange = (value: any) => {
    setValue(value);
  };

  if (!list) {
    return null;
  }

  return (
    <div className={'radio-buttons'}>
      {
        list.map((item: any) => (
          <label className="radio-buttons__label" key={item.id}>
            <input
              className={'radio-buttons__input'}
              type="radio"
              value={item.value}
              checked={item.value === value.value}
              onChange={() => handleRadioChange(item)}
            />
            <span className="radio-buttons__checkmark" />
            <span className={'radio-buttons__value'}>{item.value}</span>
          </label>
        ))
      }
    </div>
  );
};

export default RadioButtons;