import React, {useEffect, useRef, useState} from 'react';

import arrow from './assets/images/arrow.svg';

import './DropDown.scss';

interface IDropDown {
  list: any;
  value: any;
  setValue: any;
  setVisibleForm?: any;
  marginBottom?: number | string;
}

const DropDown: React.FC<IDropDown> = ({list, marginBottom, setValue, value, setVisibleForm}) => {
  const rootEl = useRef<any>(null);

  const [visibleDropdown, setVisibleDropdown] = useState(false);

  const categoriesInlineStyle = {
    marginBottom: marginBottom,
  };

  useEffect(() => {
    const onClick = (e: any) => rootEl.current?.contains(e.target) || setVisibleDropdown(false);

    document.addEventListener('click', onClick);

    return () => document.removeEventListener('click', onClick);
  }, []);

  const selectItem = (item: any) => {
    setValue(item);
    setVisibleForm && setVisibleForm(true);
    changeVisibleDropDown();
  };

  const changeVisibleDropDown = () => {
    setVisibleDropdown(innerVisibleDropdown => !innerVisibleDropdown);
  };

  return (
    <div className={'drop-down'} style={categoriesInlineStyle} ref={rootEl}>
      <h3 className={'drop-down__title'}>{setVisibleForm ? 'Город' : 'Адрес'}</h3>
      <div className={'drop-down__container'}>
        <div
          className={
            visibleDropdown
              ? 'drop-down__select drop-down__select_active'
              : 'drop-down__select'
          }
          onClick={changeVisibleDropDown}>
          <span
            className={
              visibleDropdown
                ? 'drop-down__select-categories drop-down__select-categories_active'
                : 'drop-down__select-categories'
            }>
            {value.value ? value.value :  setVisibleForm ? 'Выбрать город' : 'Выбрать адрес'}
          </span>
          <img
            src={arrow}
            alt="arrow"
            className={
              visibleDropdown
                ? 'drop-down__arrow drop-down__arrow_rotate'
                : 'drop-down__arrow'
            }
          />
        </div>
        <div
          className={
            visibleDropdown
              ? 'drop-down__dropdown drop-down__dropdown_show'
              : 'drop-down__dropdown'
          }>
          {
            list?.map((item: any, index: number) => (
              <span
                className={
                  item.value === value.value
                    ? 'drop-down__name-categories drop-down__name-categories_active'
                    : 'drop-down__name-categories'
                }
                key={index}
                onClick={() => selectItem(item)}>
                {item.value}
              </span>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default DropDown;
