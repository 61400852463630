import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {CSSTransition, SwitchTransition} from 'react-transition-group';

import {useAppSelector} from 'utils/hooks';
import {suffix} from 'utils/suffix';

import {allSetCheckboxInCart, multipleDeleteItemCart} from 'store/components/CartSlice';
import {GetIcon} from 'store/enums/normalizedIcons';

import Title from 'components/title/Title';
import Button from 'components/button/Button';

import Checkbox from './assets/components/listCarts/components/checkbox/Checkbox';
import ListCarts from './assets/components/listCarts/ListCarts';
import PurseList from '../prizes/assets/components/purseList/PurseList';

import warning from './assets/images/warning.svg';

import './Cart.scss';

export interface ICart {
  id: number | string;
  title: string;
  checkbox: boolean;
  time: string;
  price: number | string;
  counter: number;
  total: () => number;
}

const Cart = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const cart = useAppSelector(state => state.cart.cart);
  const total = useAppSelector(state => state.cart.total);
  const markup = useAppSelector(state => state.cart.total_markup);
  const ndfl = useAppSelector(state => state.cart.total_tax);
  const purse = useAppSelector(state => state.dashboard.purse);
  const jivoItem1 = document.getElementById('jivo_custom_widget');
  const jivoItem2 = document.getElementById('jivo_custom_widget2');
  const jivoItem3 = document.getElementById('jivo_custom_widget3');

  const ndflTotal =
    purse?.is_taxable_eps
    && ndfl
    && purse.tax_payer === 'participant'
      ? Math.round(ndfl)
      : 0;

  if (!cart) {
    return null;
  }

  const [checkbox, setCheckbox] = useState<boolean>(false);
  const multipleDeleteVisible: any = cart.filter((item) => item.checkbox).length > 0;
  const totalMoreBalance = +total > (purse?.balance || 0);
  const length = cart.filter(item => item.is_eps).reduce((prev, curr) => prev + +curr?.amount, 0);
  const lengthPlastic = cart.filter(item => !item.is_eps).reduce((prev, curr) => prev + +curr?.amount, 0);

  const multipleDeleteCard = () => {
    dispatch(multipleDeleteItemCart());
  };

  const allSetInTrueCheckbox = () => {
    dispatch(allSetCheckboxInCart(!checkbox));
  };

  const openPage = (path: string) => {
    history.push(path);
  };

  const openPlaceAnOrder = () => {
    openPage('place-order');
  };

  useEffect(() => {
    cart.find(cartItem => !cartItem.checkbox) ? setCheckbox(false) : setCheckbox(true);
    if (cart?.length) {
      jivoItem1?.classList.add('full-cart');
      jivoItem2?.classList.add('full-cart');
      jivoItem3?.classList.add('full-cart');
    } else {
      jivoItem1?.classList.remove('full-cart');
      jivoItem2?.classList.remove('full-cart');
      jivoItem3?.classList.remove('full-cart');
    }
  }, [cart]);

  const suffixCart = (value: string) => {
    const qtyCurrent = value === 'plastic' || value === 'card' ? lengthPlastic : length;

    return suffix({value, qty: qtyCurrent});
  };

  return (
    <div className={'cart'}>
      <Title>Корзинa</Title>
      <p className={'cart__subtitle'}>
        Вы можете выбрать сертификаты на общую сумму{' '}
        <span className={'cart__user-balance'}>{purse?.balance} ₽</span>
      </p>
      <div className={'cart__purses'}>
        <PurseList />
      </div>
      <div className={'cart__container'}>
        <SwitchTransition mode={'out-in'}>
          <CSSTransition
            key={`${cart?.length > 0}`}
            timeout={500}
            classNames={'cart__switch-container'}>
            {
              cart?.length > 0 ? (
                <div className={'cart__container'}>
                  <div className={'cart__warning'}>
                    <img
                      src={warning}
                      alt="warning"
                      className={'cart__warning-icon'}
                    />
                    <p className={'cart__warning-text'}>
                      Сертификат (ы) зарезервированы на 20 минут, пожалуйста завершите оформление заказа
                    </p>
                  </div>
                  <div className={'cart__checkbox-container'}>
                    <div className={'cart__checkbox'} onClick={allSetInTrueCheckbox}>
                      <Checkbox checkbox={checkbox} />
                      <span className={'cart__checkbox-label'}>Выбрать все</span>
                    </div>
                    <a
                      onClick={multipleDeleteCard}
                      className={
                        multipleDeleteVisible
                          ? 'cart__link cart__link_active'
                          : 'cart__link'
                      }>
                      Удалить выделенное
                    </a>
                  </div>
                  <div className={'cart__content'}>
                    <div className={'cart__table'}>
                      <ListCarts />
                    </div>
                    <div className={'cart__info-container'}>
                      <div className={'cart__info'}>
                        <h3 className={'cart__title cart__title_desktop'}>В корзине</h3>
                        <div className={'cart__total-container'}>
                          <span className={'cart__qty cart__qty_desktop'}>
                            {length ? `${length} сертификат${suffixCart('certificate')}` : null}
                            {length && lengthPlastic ? <br/> : ''}
                            {
                              lengthPlastic
                                ? `${lengthPlastic} пластиков${suffixCart('plastic')} карт${suffixCart('card')}`
                                : null
                            }
                          </span>
                          <span className={'cart__title cart__title_mobile'}>
                            В корзине
                            <div>
                              <span className={'cart__qty cart__qty_mobile'}>
                                {length ? `${length} сертификат${suffixCart('certificate')}` : null}
                                {length && lengthPlastic ? ', ' : ''}
                                {
                                  lengthPlastic
                                    ? `${lengthPlastic} пластиков${suffixCart('plastic')} карт${suffixCart('card')}`
                                    : null
                                }
                              </span>
                            </div>
                          </span>
                          <div className={'cart__markup-container'}>
                            {markup
                              ? (
                                <div className={'cart__commission-container'}>
                                  <span className={'cart__commission-label'}>
                                    Общая комиссия:
                                  </span>
                                  <span className={'cart__commission'}>
                                    {markup} ₽
                                  </span>
                                </div>
                              )
                              : null
                            }
                            {ndflTotal
                              ? (
                                <div className={'cart__commission-container'}>
                                  <span className={'cart__commission-label'}>
                                    НДФЛ:
                                  </span>
                                  <span className={'cart__commission'}>
                                    {ndflTotal} ₽
                                  </span>
                                </div>
                              )
                              : null
                            }
                          </div>

                          <span className={'cart__total-label'}>
                            Общая стоимость
                          </span>
                          <span className={'cart__total'}>
                            {total} ₽
                          </span>
                        </div>
                      </div>
                      <span
                        className={`
                          cart__error ${totalMoreBalance ? 'cart__error_show' : ''}
                        `}>
                        Не достаточно средств
                      </span>
                      <Button
                        onClick={openPlaceAnOrder}
                        disabled={totalMoreBalance}
                        borderRadius={8}
                        className={'cart__button cart__button_desktop'}>
                        Перейти к оформлению
                      </Button>
                      <div className={'cart__info-container_mobile'}>
                        <div className={'cart__info'}>
                          <span className={'cart__total-label cart__total-label_mobile'}>
                            Общая стоимость
                          </span>
                          <span className={'cart__total_mobile'}>
                            {total} ₽
                          </span>
                        </div>
                        <Button
                          onClick={openPlaceAnOrder}
                          disabled={totalMoreBalance}
                          borderRadius={8}
                          className={'cart__button'}>
                          Перейти к оформлению
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={'cart__container-button'}>
                  <Button
                    onClick={() => openPage('prizes')}
                    icon={<GetIcon iconName={'Tile'} />}
                    borderRadius={8}
                    height={'46px'}
                    className={'cart__button'}>
                    Выбрать сертификат
                  </Button>
                </div>
              )
            }
          </CSSTransition>
        </SwitchTransition>
      </div>
    </div>
  );
};

export default Cart;
