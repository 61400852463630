import React, {useState} from 'react';

import {useAppSelector} from 'utils/hooks';
import {GetIcon} from 'store/enums/normalizedIcons';

import SecondInput from 'components/secondInput/SecondInput';
import PrivateDeliveryRadioButton from './components/privateDeliveryRadioButtons/PrivateDeliveryRadioButton';
import Form from '../form/Form';
import DropDown from '../dropDown/DropDown';

import './PrivateDelivery.scss';

const PrivateDelivery = ({...props}: any) => {
  const projectSettings = useAppSelector((state) => state.auth.projectSettings);

  const [visibleForm, setVisibleForm] = useState(false);

  return (
    <div className={'private-delivery'}>
      <div className={'private-delivery__form-container'}>
        <h3 className={'private-delivery__title'}>
          {
            props.selectedCity.value
              ? `Стоимость доставки по г. ${props.selectedCity.value} - ${props.selectedCity.price} рублей`
              : 'Выберите город для расчета стоимости.'
          }
        </h3>
        {
          projectSettings!.delivery_addresses && projectSettings!.delivery_addresses.length > 3  ? (
            <DropDown
              list={projectSettings!.delivery_cities}
              value={props.selectedCity}
              setValue={props.setSelectedCity}
              setVisibleForm={setVisibleForm}
            />
          ) : (
            <PrivateDeliveryRadioButton
              list={projectSettings!.delivery_cities}
              value={props.selectedCity}
              setValue={props.setSelectedCity}
              setVisibleForm={setVisibleForm}
            />
          )
        }
        {
          visibleForm ? (
            <>
              <SecondInput
                value={props.selectedAddress}
                setValue={props.setSelectedAddress}
                marginBottom={16}
                title={'Введите адрес'}
              />
              <SecondInput
                value={props.comment}
                setValue={props.setComment}
                marginBottom={16}
                title={'Комментарий'}
              />
              <Form {...props} delivery_type={'private_delivery'} />
            </>
          ) : null
        }
      </div>
      <div className={'private-delivery__info-container'}>
        <GetIcon iconName={'PlasticInfo'} />
        <p className={'private-delivery__text'}>
          Прием заявок на обмен и доставку заказанных пластиковых карт, осуществляется следующим образом
        </p>
        <h3 className={'private-delivery__item'}>Доставка на личный адрес клиента:</h3>
        <ul className={'private-delivery__list'}>
          <li className={'private-delivery__text'}>Пластиковые карты доставляются по адресу, указанному при оформлении заказа.</li>
          <li className={'private-delivery__text'}>Стоимость доставки рассчитывается индивидуальное по каждому выбранному городу.</li>
          <li className={'private-delivery__text'}>Срок доставки — в течение 5 рабочих дней с момента оформления заказа.</li>
          <li className={'private-delivery__text'}>График работы курьерской службы в будни 9:00-18:00. Выходной – суббота и воскресенье.</li>
        </ul>
      </div>
    </div>
  );
};

export default PrivateDelivery;