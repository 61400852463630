import React, {createRef, useEffect, useRef, useState} from 'react';

import {useAppDispatch, useAppSelector} from 'utils/hooks';
import {lazyLoading} from 'utils/functions';

import {GetIcon} from 'store/enums/normalizedIcons';

import {getHistoryOrders} from 'store/components/DashboardSlice';

import Table from './components/Table/Table';
import Button from 'components/button/Button';
import UpwardButton from 'components/upwardButton/UpwardButton';

import './PullBasket.scss';

const PullBasket = ({stepLen}: {stepLen: number}) => {
  const liRef = useRef<any>();
  const observerLoader = useRef<any>();
  const lastItem = createRef<any>();

  const dispatch = useAppDispatch();

  const {history} = useAppSelector(state => state.prizes.loading);
  const historyOrders = useAppSelector(state => state.dashboard.historyOrders);
  const historyTotal = useAppSelector(state => state.dashboard.historyTotal);
  const colors = useAppSelector((state) => state.settings.colors);
  const domain = useAppSelector((state) => state.settings.currentDomainName);
  const purse = useAppSelector(state => state.dashboard.purse);

  const [step, setStep] = useState(0);
  const [prevStep, setPrevStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const restrictionHistoryLen = window.innerWidth > 540 ? historyOrders.length : 5;
  const [restrictionHistory, setRestrictionHistory] = useState(historyOrders.slice(0, restrictionHistoryLen));
  const [scroll, setScroll] = useState<number>(Math.round(
    document.documentElement.scrollHeight / (liRef.current?.offsetHeight || 300)
  ));

  const visibleButton = historyOrders.length >= 6 && restrictionHistory.length < historyOrders.length;
  const disabledNewCard = step * restrictionHistoryLen >= historyOrders.length;
  const {loadButtonColor} = colors[domain];

  useEffect(() => {
    lazyLoading(lastItem, observerLoader, setScroll, 12);
  }, [lastItem]);

  useEffect(() => {
    if (liRef.current?.offsetTop <= scroll && !loading) {
      setLoading(true);
      setStep(step + 1);
    }
  }, [scroll]);

  window.addEventListener('scroll', () => {
    setScroll(window.pageYOffset + window.innerHeight);
  });

  if (!historyOrders) {
    return null;
  }

  useEffect(() => {
    if (step >= 1 && step > prevStep && (historyTotal > historyOrders.length)) {
      dispatch(getHistoryOrders({from: step * stepLen, to: (step + 1) * stepLen})).then(() => {
        setLoading(false);
        setPrevStep(step);
      });
    }
  }, [step]);

  const lengthVirtual = (value: any) => value.filter((item: any) => item.is_eps).length;
  const lengthPlastic = (value: any) => value.filter((item: any) => !item.is_eps).length;

  return (
    <div className={history && !historyOrders.length ? 'pull-basket pull-basket_hide' : 'pull-basket'}>
      <h3 className={'pull-basket__title'}>История оформления заказов</h3>
      <div className={'pull-basket__status-notification'}>
        <GetIcon
          iconName={'InfoIcon'}
          fill={colors[domain].infoIcon}
          secondaryFill={colors[domain].infoIcon}
          width={'24'}
        />
        <div>Статусы заказов обновляются каждые 3 минуты</div>
      </div>
      <ul className={'pull-basket__history'}>
        {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          historyOrders.map(({id, order}, index, array) => (
            <li
              ref={scroll - 1 === index ? lastItem : liRef}
              className={
                index < scroll
                  ? 'pull-basket__order pull-basket__order_active'
                  : 'pull-basket__order'
              }
              key={index}>
              <p className={'pull-basket__id-and-date'}>
                №{order.number} заказа – от {new Date(order.created_at).toLocaleDateString()}
                <br/>
                {order.is_changed ? (<span className={'pull-basket__is-changed'}>Заказ изменен</span>) : null}
              </p>
              <div className={'pull-basket__order-sum-container'}>
                {
                  order.tax ? (
                    <p className={'pull-basket__order-sum'}>
                      НДФЛ:{' '}
                      <span className={'pull-basket__order-sum_bold'}>{order.tax}</span>
                    </p>
                  ) : null
                }
                <p className={'pull-basket__order-sum'}>
                  Сумма заказа:{' '}
                  <span className={'pull-basket__order-sum_bold'}>{order.sum}</span>
                </p>
              </div>

              <table className={'pull-basket__cards-container'}>
                <thead className={'pull-basket__table-head_desktop'}>
                  <tr>
                    <td className={'pull-basket__cards-title'}>Состав заказа</td>
                    <td></td>
                    <td></td>
                    <td className={'pull-basket__cards-number'}>Номинал</td>
                    <td className={'pull-basket__cards-number'}>
                      {order.positions.findIndex((item) => {
                        return item.markup_nominal > 0;
                      }) >= 0 ? (
                          'Комиссия'
                        ) : null}
                    </td>
                    <td className={'pull-basket__cards-title'}>Статус заказа</td>
                  </tr>
                </thead>
                {
                  lengthVirtual(order.positions) ? (
                    <tr>
                      <td colSpan={5}>
                        <h3 className={'pull-basket__type-title'}>Электронные сертификаты</h3>
                      </td>
                    </tr>
                  ) : null
                }
                <Table positions={order.positions.filter((item: any) => item.is_eps)} />
                {
                  lengthPlastic(order.positions) ? (
                    <>
                      <tr>
                        <td colSpan={4}>
                          <h3 className={'pull-basket__type-title'}>Пластиковые карты</h3>
                        </td>
                      </tr>
                      <Table positions={order.positions.filter((item: any) => !item.is_eps)} />
                    </>
                  ) : null
                }
              </table>
            </li>
          ))
        }
      </ul>
      {
        visibleButton ? (
          <div className={'pull-basket__button-container'}>
            <Button
              disabled={disabledNewCard}
              className={'load-button'}
              icon={<GetIcon iconName={'LoadButton'} fill={loadButtonColor} secondaryFill={loadButtonColor} />}
              onClick={() => setStep(step + 1)}>
              Показать еще
            </Button>
          </div>
        ) : null
      }
      <UpwardButton />
    </div>
  );
};

export default PullBasket;
