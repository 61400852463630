import React, {Dispatch, SetStateAction} from 'react';

import {limiter} from 'utils/limiter';
import {useAppDispatch, useAppSelector} from 'utils/hooks';
import {showPopup} from 'store/components/PopupSlice';
import {createOrder} from 'store/components/DashboardSlice';

import UserField from '../../../profile/assets/components/userField/UserField';
import SecondInput from 'components/secondInput/SecondInput';
import VerifiedEmail from 'components/verifiedEmail/VerifiedEmail';
import Button from 'components/button/Button';

interface IForm {
  name: string;
  secondName: string;
  phone: string;
  email: string;
  loading: boolean;
  loadingUser: boolean;
  comment?: string;
  delivery_type?: string;
  selectedCity?: { value: string };
  selectedAddress?: string | { value?: string };
  setName: Dispatch<SetStateAction<string>>;
  setSecondName: Dispatch<SetStateAction<string>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

const Form: React.FC<IForm> = ({
  name,
  secondName,
  setName,
  setSecondName,
  email,
  phone,
  loadingUser,
  setLoading,
  loading,
  delivery_type,
  comment,
  selectedCity,
  selectedAddress
}) => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user);
  const is_required_email = useAppSelector((state) => state.auth.is_required_email);

  if (!user) {
    return null;
  }

  const disabledButton =
    (name && secondName && phone) === ''
    || (email === '' && is_required_email)
    || phone.includes('_')
    || (!user.status && is_required_email)
    || (email !== user.email)
    || (
      delivery_type &&
      !(typeof selectedAddress === 'string' ? selectedAddress : selectedAddress && selectedAddress.value)
    ) || loadingUser;

  const openPopup = () => {
    dispatch(showPopup('goToAccount'));
  };

  const placeOrder = () => {
    let payload: any = {};

    if (delivery_type === 'private_delivery') {
      payload = {
        delivery_type,
        Comment: comment,
        delivery_city: selectedCity && selectedCity.value,
        delivery_address: typeof selectedAddress === 'string' && selectedAddress,
      };
    } else if (delivery_type === 'default_delivery') {
      payload = {
        delivery_type,
        delivery_address: typeof selectedAddress === 'object' && selectedAddress.value,
      };
    }

    setLoading(true);

    dispatch(createOrder(payload)).then(() => {
      setLoading(false);
    });
  };

  return (
    <>
      <div>
        <SecondInput
          value={name}
          setValue={(value: string) => limiter(value, setName, 50)}
          marginBottom={16}
          title={'Имя'}
        />
        <SecondInput
          value={secondName}
          setValue={(value: string) => limiter(value, setSecondName, 50)}
          marginBottom={16}
          title={'Фамилия'}
        />
        <div>
          <div className={'profile-info__email'}>
            <UserField
              title={'Почта'}
              isMask={false}
              data={user.email}
              onClick={openPopup}
            />
            {user.email && is_required_email
              ? (<VerifiedEmail />)
              : null
            }
          </div>
          <UserField
            title={'Телефон'}
            isMask={true}
            data={user.phone}
            onClick={openPopup}
          />
        </div>
      </div>
      <Button
        onClick={placeOrder}
        loading={loading}
        disabled={disabledButton || loading}
        className={'place-order__button'}>
        Оформить заказ
      </Button>
    </>
  );
};

export default Form;